/// <reference path="common_controllers.ts"/>

class zenField {
    static globalid: number = 0;
    public id: number;
    public type: string;
    public name: string;

    public label: string;
    public description: string;
    public placeholder: string;

    public value: string;
    public required: boolean;
    public options: Array<{ id: number, name: string }>;

    public invokeonchange: boolean;
    public invokeonblur: boolean;
    public invokeonclick: boolean;
    public forms: any;

    constructor() {
        
        zenField.globalid++;
        this.id = Date.now();
        this.type = 'text field';
        this.name = 'element' + zenField.globalid;

        this.label = 'element ' + zenField.globalid;
        this.description = '';
        this.placeholder = '';

        this.value = '';
        this.required = false;
        this.options = [];

        this.invokeonchange = false;
        this.invokeonblur = false;
        this.invokeonclick = false;
    }
    static component(type: string) {
        var result = new zenField();
        result.type = type;
        if(result.type == 'form' || result.type == 'table'){
            result.forms = [];
           
        }
        return result;
    }
}


class zenFormElementCtrl {
    static $inject: Array<string> = ['$scope'];

    constructor(
        public $scope
    ) {
    }
    remove(item) {
        for (var i = 0; i < this.$scope.model.options.length; i++) {
            if (this.$scope.model.options[i].id == item) {
                this.$scope.model.options.splice(i, 1);
                this.$scope.me.checkSelected(this.$scope);
                continue;
            }
        }
    }
    add(id, name) {
        if (!this.$scope.model.options) {
            this.$scope.model.options = [];
        }
        this.$scope.model.options.push({ id: this.$scope.newid, name: this.$scope.newvalue });
        this.$scope.newid = '';
        this.$scope.newvalue = '';
        this.$scope.me.checkSelected(this.$scope);
    }
    button() {
        this.$scope.model.value = true;
        this.$scope.invoke();
    }
}
app.controller("zenFormElementCtrl", zenFormElementCtrl);

class zenFormElement implements ng.IDirective {
    //restrict = 'E';
    //require = 'ngModel';
    public formsList :any;
    genericServices: GenericServiceClass;

    constructor(
        private $compile,
        private $timeout,
        private $templateCache,
        private socketService ,
        private $routeParams,
     




    ) { 
        this.genericServices = new GenericServiceClass(this.socketService.namespace.socket, this.socketService.namespace)

        this.LoadForms();
      

    }

    public model: zenField;
    public formid: string;
    static $inject: Array<string> = ['$compile', '$timeout', '$templateCache' , 'socketService','$routeParams'];
    
    scope = {
        isComponent: "@",
        isElement: "@",
        isEditor: "@",
        form: '=',
        table:'=',
        click: '&click',
        model: '=',
        removeextraform:'&',
        invoke: '&invoke',
        addextraform: '&',
      
    };

        
    public RemoveItem(list ,  index){ 
    }

    public LoadForms(){
        var formsquery = "_type eq 'form'";
        (async ()=> {
            var fromId = this.$routeParams.id;
            if(fromId){
                formsquery+= " and _id ne '"+fromId+"'"
            }
            this.formsList = await this.genericServices.get(formsquery, 200, 0, null, null, 'workflows');
       
     
        })();
     
    };
    

    
    public controller: string = 'zenFormElementCtrl';
    public controllerAs: string = 'vm';
    public replace: boolean = true;

    public checkSelected(scope: any) {
        if (!scope.model) { return; }
        if (!scope.model.options) { return; }



        if (scope.model.options.length == 0) {
            var result = scope.element[0].querySelectorAll('input');
            if (result.length > 0) {
                var t = angular.element(result[0]);
                var type = t.attr("type");
                //var type = result[0].attr("type");
                if (type == 'checkbox') {
                    scope.model.value = (scope.model.value == 'true' || scope.model.value == true);
                }
                return;
            }
            result = scope.element[0].querySelectorAll('button');
            if (result.length > 0) {
                scope.model.value = false;
            }
        }
        var found: boolean = false;
        scope.model.options.forEach(element => {
            if (element.id == scope.model.value) { found = true; }
        });
        if (!found) {
            if (!scope.model.value || scope.model.value == '') {

                if (scope.model.options && scope.model.options.length > 0) {
                    //if(scope.model.value==null || scope.model.value==undefined || scope.model.value=='') {
                    scope.model.value = scope.model.options[0].id;
                    //}
                }
            }
        }

    }

    updateTemplate(scope: any, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) {
        var template: any;
        if (scope.model) {
            var templatename = scope.model.type;
            if (scope.isComponent == 'true') {
                templatename = templatename + ' component';
            } else if (scope.isEditor == 'true') {
                templatename = templatename + ' editor';
            }
            var template = this.$templateCache.get(templatename);
            if (!template) {
                console.error('failed locating template ' + scope.model.type);
                return;
            }
            if (!scope.model.id) { scope.model.id = Date.now(); }
            element.attr('id', scope.model.id);
        } else {
            template = '<div></div>';
        }

        element.html(template);
        this.$compile(element.contents())(scope);
        //var fe = element.children().first();
        var fe = element.children((element.children()[0] as any));

        if (scope.isComponent == 'true' || scope.isElement == 'true') {
            fe.attr('draggable', 'true');
            fe.addClass('droptarget');
            fe.bind('dragstart', (event) => {
                this.dragstart(event, scope);
            });
            fe.bind('dragend', (event) => {
                this.dragend(event, scope);
            });
        }
        if (scope.isComponent == 'true') {
            fe.addClass('component');
        }
        if (scope.isElement == 'true') {
            //element.find('input, textarea, button, select').attr('disabled','disabled');
            //element.find('input, textarea, select').attr('disabled','disabled');
            element.find('input').attr('disabled', 'disabled');
            element.find('textarea').attr('disabled', 'disabled');
            element.find('select').attr('disabled', 'disabled');

            fe.on('dragover', (event) => {
                // By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element
                event.preventDefault();
            });
            fe.addClass('droptarget');

            fe.on('dragleave', (event) => {
                this.dragleave(event, scope);
            });

            fe.on('dragenter', (event) => {
                this.dragenter(event, scope);

            });
            fe.on('drop', (event, ) => {
                this.drop(event, scope, element);
            });

            element.on('click', (event) => {
                this.click(event, scope, element);
            });
        }
        if (scope.model && scope.isComponent != 'true' && scope.isElement != 'true' && scope.isEditor != 'true') {
            if (scope.model.invokeonchange == true) {
                scope.$watch('model["value"]', function (newValue, oldValue, scope) {
                    if (newValue != oldValue) {
                        scope.invoke();
                    }
                });
            }
            if (scope.model.invokeonblur == true) {
                fe.on('focusout', (event) => {
                    this.$timeout(() => {
                        scope.invoke();
                    }, 50);
                });
            }
            if (scope.model.invokeonclick == true) {
                fe.on('click', (event) => {
                    this.$timeout(() => {
                        scope.invoke();
                    }, 50);
                });
            }
        }
    }

    public link(scope: any, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) {
        scope.me = this;
        scope.element = element;
        scope.$watch('model["options"]', function (newValue, oldValue, scope) {
            scope.me.checkSelected(scope);
        });
        if (!scope.model) {
            if (scope.isEditor != 'true') {
                console.error('missing a model!');
            } else {
                scope.$watch((scope) => { return (scope.model ? scope.model.type : null) }, (newVal, oldVal) => {
                    scope.me.updateTemplate(scope, element, attrs, ctrl);
                    // if(scope.model) {
                    // }
                });
            }
            return
        }
        this.updateTemplate(scope, element, attrs);
    }
    dragstart(event, scope) {
        event.dataTransfer = event.originalEvent.dataTransfer;

        event.dataTransfer.setData("id/text", scope.model.id);
        event.dataTransfer.setData("template/text", scope.model.type);
        if (scope.isComponent || scope.isComponent == 'true') {
            event.dataTransfer.setData("elementtype/text", 'component');
        } else {
            event.dataTransfer.setData("elementtype/text", 'element');
        }
        var ele = angular.element(event.target);
        ele[0].style.opacity = "0.4";
    }
    dragend(event, scope) {
        var ele = angular.element(event.target);
        ele[0].style.opacity = "1";
    }
    dragleave(event, scope) {
        var ele = angular.element(event.target);
        if (ele.hasClass("hovertarger")) {
            ele.removeClass('hovertarger');
        }
    }
    dragenter(event, scope) {
        var ele = angular.element(event.target);

        var parent = ele.parent().hasClass('droptarget');
        var children = ele.children().hasClass('droptarget');

        if (parent || children) {
            ele.addClass('hovertarger');
        }
    }

    drop(event, scope, element) {
        event.preventDefault();
        event.stopPropagation();
        var ele = angular.element(event.target);
        if (ele.hasClass("hovertarger")) {
            ele.removeClass('hovertarger');
        }
        event.dataTransfer = event.originalEvent.dataTransfer;
        var id = event.dataTransfer.getData("id/text");
        var template = event.dataTransfer.getData("template/text");
        var elementtype = event.dataTransfer.getData("elementtype/text");

        if (elementtype == "component") {
            if (!scope.form) { scope.form = []; }
            scope.form.push(zenField.component(template));

            if (!scope.$$phase) { scope.$apply(); }
        } else {
            var source = scope.form.map(function (e) { return e.id; }).indexOf(Number(id));
            var target = scope.form.map(function (e) { return e.id; }).indexOf(Number(scope.model.id));

            scope.form[source] = scope.form.splice(target, 1, scope.form[source])[0];
            if (!scope.$$phase) { scope.$apply(); }
        }
    }

    click(event, scope, element) {
        var target = scope.form.map(function (e) { return e.id; }).indexOf(Number(scope.model.id));
        scope.click({ model: scope.form[target] });
    }


}
angular.module('webApp').directive('zenFormElement', ['$compile', '$timeout', '$templateCache', 'socketService' , '$routeParams' , ($compile, $timeout, $templateCache,socketService ,$routeParams  ) => new zenFormElement($compile, $timeout, $templateCache , socketService , $routeParams)]);

class zenForm implements ng.IDirective {
    static $inject: Array<string> = ['$compile'];
    constructor(
        private $compile
    ) {
        if (this.$compile) { }
    }
    scope = {
        form: "=",
        property: "=",
        isBuilder: "@"
    };
    link(scope: any, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) {
        if (scope.isBuilder || scope.isBuilder == "true") {
            element.addClass('droptarget');
        }
        element.on('drop', (event) => {
            this.drop(event, scope);
        });

        element.on('dragover', (event) => {
            // By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element
            event.preventDefault();
        });

        element.on('dragleave', (event) => {
            this.dragleave(event, scope);
        });

        element.on('dragenter', (event) => {
            this.dragenter(event, scope);
        });

        var trashcan = angular.element(document.querySelector('.trashcan'));
        trashcan.on('dragenter', (event) => {
            this.dragenter(event, scope);
        });
        trashcan.on('dragleave', (event) => {
            this.dragleave(event, scope);
        });
        trashcan.on('dragover', (event) => {
            event.preventDefault();
        });
        trashcan.on('drop', (event: any) => {
            event.preventDefault();
            event.stopPropagation();
            var ele = angular.element(event.target);
            if (ele.hasClass("hovertarger")) {
                ele.removeClass('hovertarger');
            }
            event.dataTransfer = event.originalEvent.dataTransfer;
            var id = event.dataTransfer.getData("id/text");
            var source = scope.form.map(function (e) { return e.id; }).indexOf(Number(id));
            if (source > -1) {
                scope.property = null;
                scope.form.splice(source, 1);
                if (!scope.$$phase) { scope.$apply(); }
            }
        });

    }
    dragleave(event, scope) {
        var ele = angular.element(event.target);
        if (ele.hasClass("hovertarger")) {
            ele.removeClass('hovertarger');
        }
    }
    dragenter(event, scope) {
        var ele = angular.element(event.target);
        if (ele.hasClass("droptarget")) {
            ele.addClass('hovertarger');
        }
        if (ele.hasClass("trashcan")) {
            ele.addClass('hovertarger');
        }
    }
    drop(event, scope) {
        event.preventDefault();
        event.stopPropagation();
        var ele = angular.element(event.target);
        if (ele.hasClass("hovertarger")) {
            ele.removeClass('hovertarger');
        }
        event.dataTransfer = event.originalEvent.dataTransfer;
        var template = event.dataTransfer.getData("template/text");
        var elementtype = event.dataTransfer.getData("elementtype/text");
        if (elementtype == "component") {
            if (!scope.form) { scope.form = []; }
            scope.form.push(zenField.component(template));
            if (!scope.$$phase) { scope.$apply(); }
        }
    }

}
angular.module('webApp').directive('zenForm', ['$compile', ($compile) => new zenForm($compile)]);






